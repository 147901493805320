<template>
  <sun-form>
    <asterix-form-modal title="Edit Platform Fee" closable @submit="onSubmitForm" @cancel="$emit('cancel')">
      <template #content>
        <form-row>
          <template #left>
            <sun-label-group text="Open Fee">
              <asterix-input
                :id="`${entity}-open-fee`"
                v-model="entity.openFee"
                name="openFee"
                :auto-validate="false"
                :max="100"
                :min="0"
                placeholder="%"
                required="required"
                type="number"
                validate-on-blur
              />
            </sun-label-group>
          </template>
          <template #right>
            <sun-label-group text="Deal Fee">
              <asterix-input
                :id="`${entity}-deal-fee`"
                v-model="entity.dealsFee"
                name="dealFee"
                :auto-validate="false"
                :max="100"
                :min="0"
                placeholder="%"
                required="required"
                type="number"
                validate-on-blur
              />
            </sun-label-group>
          </template>
        </form-row>
      </template>
      <template #footer>
        <div class="flex w-full justify-between text-xs mt-3 shadow-sm sm:mt-0 sm:w-auto">
          <save-button class="mr-2" />
          <sun-button variant="pill" class="custom-p-1" color="white" @click="$emit('cancel')"> Cancel </sun-button>
        </div>
      </template>
    </asterix-form-modal>
  </sun-form>
</template>

<script>
import { mapActions } from 'vuex';
import AsterixFormModal from '@/components/organisms/shared/AsterixFormModal';
import FormRow from '@/components/atoms/FormRow/FormRow';
import AsterixInput from '@/components/atoms/AsterixInput';
import SaveButton from '@/components/atoms/SaveButton';
import PlatformFee from '@/entities/dashboardSSP/PlatformFee';
import { createPlatformFee, updatePlatformFee } from '@/services/modules/dashboardSSP/platform';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import { deepClone } from '@/utils/deepClone';

export default {
  name: 'EditFeeModal',
  components: { AsterixFormModal, FormRow, AsterixInput, SaveButton },
  props: {
    fee: {
      type: Object,
      default: () => new PlatformFee(),
    },
  },
  data: () => ({
    entity: null,
  }),
  computed: {
    startDate() {
      return {
        startDate: this.fee.startDate,
        endDate: this.fee.startDate,
      };
    },
  },
  created() {
    this.entity = deepClone(this.fee);
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    async onSubmitForm({ form, valid }) {
      if (!valid) return;
      await this.save(form);
    },
    async save(form) {
      const createdOrUpdated = !this.fee.id ? 'created' : 'updated';
      try {
        const platformFeeObject = this.setPlatformFeeObject(form);
        if (!this.fee.id) {
          await createPlatformFee(platformFeeObject);
        } else {
          await updatePlatformFee(platformFeeObject);
        }
        this.createToast(
          Toast.success(`Platform Fee ${createdOrUpdated}`, `Platform Fee was ${createdOrUpdated} successfully.`)
        );
        this.$emit('save');
      } catch (error) {
        this.createToast(Toast.error(`Fee not ${createdOrUpdated}`, error));
      }
    },
    setPlatformFeeObject(form) {
      const openFee = form.openFee.value;
      const dealFee = form.dealFee.value;
      return new PlatformFee(this.fee.id, this.fee.platformId, openFee, dealFee);
    },
  },
};
</script>
