var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "sun-form",
    [
      _c("asterix-form-modal", {
        attrs: { title: "Edit Platform Fee", closable: "" },
        on: {
          submit: _vm.onSubmitForm,
          cancel: function ($event) {
            return _vm.$emit("cancel")
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("form-row", {
                  scopedSlots: _vm._u([
                    {
                      key: "left",
                      fn: function () {
                        return [
                          _c(
                            "sun-label-group",
                            { attrs: { text: "Open Fee" } },
                            [
                              _c("asterix-input", {
                                attrs: {
                                  id: `${_vm.entity}-open-fee`,
                                  name: "openFee",
                                  "auto-validate": false,
                                  max: 100,
                                  min: 0,
                                  placeholder: "%",
                                  required: "required",
                                  type: "number",
                                  "validate-on-blur": "",
                                },
                                model: {
                                  value: _vm.entity.openFee,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.entity, "openFee", $$v)
                                  },
                                  expression: "entity.openFee",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "right",
                      fn: function () {
                        return [
                          _c(
                            "sun-label-group",
                            { attrs: { text: "Deal Fee" } },
                            [
                              _c("asterix-input", {
                                attrs: {
                                  id: `${_vm.entity}-deal-fee`,
                                  name: "dealFee",
                                  "auto-validate": false,
                                  max: 100,
                                  min: 0,
                                  placeholder: "%",
                                  required: "required",
                                  type: "number",
                                  "validate-on-blur": "",
                                },
                                model: {
                                  value: _vm.entity.dealsFee,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.entity, "dealsFee", $$v)
                                  },
                                  expression: "entity.dealsFee",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex w-full justify-between text-xs mt-3 shadow-sm sm:mt-0 sm:w-auto",
                  },
                  [
                    _c("save-button", { staticClass: "mr-2" }),
                    _c(
                      "sun-button",
                      {
                        staticClass: "custom-p-1",
                        attrs: { variant: "pill", color: "white" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("cancel")
                          },
                        },
                      },
                      [_vm._v(" Cancel ")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }